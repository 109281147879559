body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfcf4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.next-previous {
  background-color: #eacfcf !important;
  color: rgb(80, 30, 30) !important;
  font-weight: 500 !important;
  margin-bottom: 15px;
}

.next-previous:hover {
  background-color: #9c6e70 !important;
  color: white !important;
  font-weight: 500 !important;
}

.exhibit-author {
  color: rgb(119, 78, 78);
  text-decoration: none;
  margin-left: 15px;
}

.exhibit-author:hover {
  color: rgb(86, 56, 56);
  text-decoration: underline;
}

.next-previous-period {
  color: rgb(80, 30, 30) !important; 
  font-weight: 475 !important;
  font-size: 10px;
  text-decoration: none;
}

.directional {
  z-index: 1000000000000;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0;
  top: 0;
  height: 100px;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
}

.progress-container {
  z-index: 1000000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  height: 75px;
  left: 0;
  width: 100%;
  bottom: 0;
  flex-direction: row; /* Change to row for horizontal alignment */
  margin: 0;
  padding: 15px;
}

.active {
  background-color: #333;
  color: white;
}

nav {
  z-index: 1000000000000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  height: 75px;
  left: 0;
  width: 100%;
  top: 0;
  background-color: rgb(80, 30, 30);
  flex-direction: row; /* Change to row for horizontal alignment */
  height: 50px;
}

.progress-container {
  bottom: 0;
  padding-bottom: 10px;
}

.circle,
.line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 75px;
  height: 50px;
  border-radius: 10%;
  background-color: white;
  font-size: 12px;
  color: black;
  box-shadow: 0 0 10px rgb(80, 30, 30);
  padding: 2px;
  text-decoration: none;
}

.info {
  margin-left: 30px;
  margin-right: 30px;
}

.circle.completed {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.line,
.line.completed {
  height: 2px;
  width: 30px;
}

.line {
  background-color: #969696;
  margin: 0;
}

.line.completed {
  background-color: #333;
  border-color: #333;
}

.nav-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.nav-item {
  color: white;
  text-decoration: none;
  padding: 15px 20px; /* Adjust padding for better spacing */
  font-size: 1rem;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0; /* Change left to 0 for horizontal alignment */
  width: 200px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.75rem;
}

.dropdown-content a:hover {
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.submenu {
  display: none;
}

.footer {
  z-index: 1000000000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: fixed;
  height: 50px;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: rgb(80, 30, 30);
  flex-direction: row; /* Change to row for horizontal alignment */
  margin: 0;
  padding: 15px;
  font-size: 1rem;
}

.information {
  position: relative;
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  max-height: fit-content;
  contain: content;
  padding-left: 20px; /* Add padding to the content */
  padding-right: 20px; /* Add padding to the content */
  overflow-y: auto;
  background-color: #fdfcf4;
  justify-content: center;
  align-items: center;
}

.period-intro {
  position: absolute;
  display: flex; 
  flex-direction: column; 
  contain: content;
  min-height: 100px;
  max-height: fit-content;
  box-shadow: 0 0 10px rgb(53, 53, 53); 
  padding: 20px; 
  margin-left: 20px; 
  margin-right: 20px; 
  margin-top: 80px;
  left: 0; 
  right: 0; 
  bottom: 0; 
  top: 0;
  border-radius: 10px;
}

@media screen and (min-width: 800px) {
  .child {
    z-index: 1000000000001;
    position: absolute;
    top: 0;
    left: 200px;
    width: 200px;
    text-align: left;
    align-items: left;
    min-height: 100%;
    height: fit-content;
    background-color: #fee9e9;
  }

  .artifact-page {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    height: fit-content;
    contain: content;
    padding: 5px;
  }

  .author-card {
    margin: 0;
    display: flex;
    flex-direction: column;
    contain: content;
    position: relative;
    margin-left: 20px;
    width: 300px;
    min-height: 100px;
    max-height: fit-content;
    box-shadow: 0 0 10px rgb(53, 53, 53);
    border-radius: 10px;
    background-color: white;
    margin-bottom: 100px !important;
  }
  
  .author-card img {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    height: 50%;
    overflow: hidden;
    object-fit: contain;
  }
  
  .author-card .author-information {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    contain: content;
    align-items: center;
    background-color: white;
  }
  
  .author-card h1 {
    margin: 0;
    position: relative;
    top: 10px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: solid 3px rgb(80, 30, 30);
    border-bottom: solid 3px rgb(80, 30, 30);
    font-size: 1.75rem;
    font-weight: bolder;
    color: rgb(80, 30, 30)
  }
  
  .author-card h2 {
    margin: 0;
    position: relative;
    margin-top: 15px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5rem;
    color: rgb(134, 56, 56);
  }
  
  .author-card h3 {
    margin: 0;
    position: relative;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: rgb(119, 78, 78);
  }
  
  .artifact-card {
    margin: 0;
    display: flex;
    flex-direction: row;
    contain: content;
    position: relative;
    left: 20px;
    width: calc(100% - 360px);
    height: 100%;
    box-shadow: 0 0 10px rgb(53, 53, 53);
    border-radius: 10px;
    align-items: center;
    background-color: white;
    margin-bottom: 100px !important;
  }

  .artifact-card img {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    min-height: auto;
    max-height: 500px;
    max-width: 50%;
    object-fit: cover;
  }

  .artifact-card .artifact-information {
    margin: 0;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    contain: content;
    align-items: center;
    background-color: white;
    overflow-y: auto;
  }

  .artifact-card h1 {
    margin: 0;
    position: relative;
    top: 10px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: solid 3px rgb(80, 30, 30);
    border-bottom: solid 3px rgb(80, 30, 30);
    font-size: 1.75rem;
    font-weight: bolder;
    color: rgb(80, 30, 30)
  }
  
  .artifact-card h2 {
    margin: 0;
    position: relative;
    margin-top: 15px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5rem;
    color: rgb(134, 56, 56);
  }
  
  .artifact-card h3 {
    margin: 0;
    position: relative;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: rgb(119, 78, 78);
  }
  .flex-test {
    position: relative;
    display: flex;
    flex-direction: row !important;
    text-align: left !important;
    align-items: left !important;
  }
}

@media screen and (max-width: 800px) {
  .nav-item {
    font-size: 0.75rem;
    text-align: center;
  }

  .dropdown-content-lr {
    right: 0;
    left: auto;
  }

  .artifact-page {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    padding-bottom: 175px;
    align-items: center;
    height: fit-content;
    align-self: center;
  }
  
  .author-card {
    margin: 0;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    contain: content;
    position: relative;
    width: 90%;
    min-height: 100px;
    max-height: fit-content;
    box-shadow: 0 0 10px rgb(53, 53, 53);
    border-radius: 10px;
    align-items: center;
    background-color: white;
    align-self: center;
  }

  .author-card .author-information {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    contain: content;
    background-color: white;
    align-self: center;
  }

  .author-card img {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    height: auto;
    width: 50%;
    align-self: center;
  }

  .author-card h1 {
    margin: 0;
    position: relative;
    top: 10px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: solid 3px rgb(80, 30, 30);
    border-bottom: solid 3px rgb(80, 30, 30);
    font-size: 1.75rem;
    font-weight: bolder;
    color: rgb(80, 30, 30);
    align-self: center;
  }
  
  .author-card h2 {
    margin: 0;
    position: relative;
    margin-top: 15px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5rem;
    color: rgb(134, 56, 56);
    align-self: center;
  }
  
  .author-card h3 {
    margin: 0;
    position: relative;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: rgb(119, 78, 78);
    align-self: center;
  }

  .artifact-card {
    margin: 0;
    display: flex;
    flex-direction: row;
    contain: content;
    position: relative;
    width: 90%;
    top: 50px;
    min-height: 100px;
    max-height: fit-content;
    box-shadow: 0 0 10px rgb(53, 53, 53);
    border-radius: 10px;
    align-items: center;
    background-color: white;
    align-self: center;
  }
  .artifact-card .artifact-information {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    contain: content;
    background-color: white;
    align-self: center;
  }

  .artifact-card img {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    height: auto;
    width: 50%;
    background-color: white;
    align-self: center;
  }

  .artifact-card h1 {
    margin: 0;
    position: relative;
    top: 10px;
    width: calc(100% - 20px);
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: solid 3px rgb(80, 30, 30);
    border-bottom: solid 3px rgb(80, 30, 30);
    font-size: 1.75rem;
    font-weight: bolder;
    color: rgb(80, 30, 30);
    align-self: center;
  }
  
  .artifact-card .artifact-information h2 {
    margin: 0;
    position: relative;
    margin-top: 15px;
    width: calc(100% - 25px);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5rem;
    color: rgb(134, 56, 56);
    align-self: center;
  }
  
  .artifact-card h3 {
    margin: 0;
    position: relative;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: rgb(119, 78, 78);
    align-self: center;
  }

  .flex-test {
    position: relative;
    display: flex;
    flex-direction: column !important;
    text-align: left !important;
    align-items: left !important;
  }

  .flex-flex {
    flex-direction: column !important;
  }

  .footer {
    flex-direction: column;
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  .nav-item {
    font-size: 0.6rem;
    text-align: center;
  }
}